<template>
  <div class="personApply_container">
    <div class="personApply_container_top_bar">
      <h1 class="personApply_container_top_bar_tilte">Entity 单位 公司</h1>
      <h1 class="personApply_container_top_bar_subtilte">APPLICATION FORM 申请表</h1>
    </div>
    <a-tabs
      :default-active-key="getCurrentTabIndex"
      :activeKey="getCurrentTabIndex"
      tab-position="top"
      :style="{ height: '60px',position: 'sticky', top:0,backgroundColor: 'rgb(248,248,248)', zIndex: 4 }"
      @prevClick="callback"
      @nextClick="callback"
      size="large"
      type="line"
      :tabBarGutter="20"
      @tabClick="handleTabClick"
    >
        <a-tab-pane  key="1">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">1</div> ENTITY DETAIL 公司信息</div>
        </template>
      </a-tab-pane>
      <a-tab-pane key="2">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">2</div> TRANSATION DETAILS 交易信息</div>
        </template>
      </a-tab-pane>
      <a-tab-pane key="3">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">3</div>CONTROL STRUCTURE 组织架构</div>
        </template>
      </a-tab-pane>
      <a-tab-pane key="4">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">4</div>AUTHORIZED 授权人</div>
        </template>
      </a-tab-pane>
      <a-tab-pane
        key="5"
      >
      <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">5</div>“PEP" SELF-CERTIFICATION PEP自我认证</div>
        </template>
      </a-tab-pane>
      <a-tab-pane
        key="6"
      >
      <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">6</div>ACKNOWLEDGEMENT 责任书</div>
        </template>
      </a-tab-pane>
    </a-tabs>
    <keep-alive>
    <router-view
    :key='$route.fullPath'/>
    </keep-alive>
    <div class="button_wrapper">
      <button v-if='isPreviewBtnShow' @click="handleToPrevBtn"  class="btn_lay pre_btn">PREVIOUS
      </button>
      <div v-else></div>
      <button
      :disabled='nextBtnAllow'
      v-if="isNextBtnShow" @click="handleToNextStep" class="btn_lay next_btn">NEXT
      </button>
      <button
      :disabled='submitBtnAllow'
      @click="handleSubmit"
      v-else class="btn_lay next_btn">{{disabled?'CLOSE':'SUBMIT'}}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { kycEntitySubmit, fetchEntity } from '@/api/common'

export default {
  data () {
    return {
      tabarIndex: 1,
      nextBtnAllow: false,
      submitBtnAllow: false
    }
  },
  created () {
    fetchEntity().then((res) => {
      const { data } = res
      this.dataLoding(data)
    })
  },
  computed: {
    ...mapGetters('entityStepOne', ['getOneData']),
    ...mapGetters('entityStepTwo', ['getTwoData']),
    ...mapGetters('entityStepThree', ['getThreeData']),
    ...mapGetters('entityStepFour', ['getFourData']),
    ...mapGetters('entityStepFive', ['getFiveData']),
    ...mapGetters('entityStepSix', ['getSixData']),
    ...mapGetters({
      disabled: 'login/getComplainceActive'
    }),
    isNextBtnShow () {
      if (this.$route.path.split('/')[3] === '') {
        return true
      }
      if (this.$route.path.split('/')[3] && this.$route.path.split('/')[3] < 6) {
        return true
      }
      return false
    },
    isPreviewBtnShow () {
      if (this.$route.path.split('/')[3] && this.$route.path.split('/')[3] > 1) {
        return true
      }
      return false
    },
    getCurrentTabIndex () {
      const tabIndex = this.$route.path.split('/')[3]
      if (tabIndex) {
        return tabIndex
      } else {
        return '1'
      }
    }
  },
  methods: {
    dataLoding (data) {
      const hasSubmit = typeof (data.Active_Non_Financial_Entity_check) !== 'undefined'
      if (!hasSubmit) {
        this.initFirstFormAction()
        this.initSecondFormAction()
        this.initThirdFormAction()
        this.initFourthFormAction()
        this.initFifthFormAction()
        this.initSixthFormAction()
      } else {
        this.ReSetFirstFormAction(data)
        this.ReSetSecondFormAction(data)
        this.ReSetThirdFormAction(data)
        this.ReSetFourthFormAction(data)
        this.ReSetFifthFormAction(data)
        this.ReSetSixthFormAction(data)
      }
    },
    ...mapActions('entityStepOne', ['initFirstFormAction', 'ReSetFirstFormAction']),
    ...mapActions('entityStepTwo', ['initSecondFormAction', 'ReSetSecondFormAction']),
    ...mapActions('entityStepThree', ['initThirdFormAction', 'ReSetThirdFormAction']),
    ...mapActions('entityStepFour', ['ReSetFourthFormAction', 'initFourthFormAction']),
    ...mapActions('entityStepFive', ['ReSetFifthFormAction', 'initFifthFormAction']),
    ...mapActions('entityStepSix', ['ReSetSixthFormAction', 'initSixthFormAction']),
    callback () {
    },
    handleToNextStep () {
      if (this.disabled) {
        this.$emit('scrollTotop')
        const nextTabIndex = +this.getCurrentTabIndex + 1
        this.$router.push('/user/entityapplication/' + nextTabIndex)
        return
      }
      this.nextBtnAllow = true
      const query = {
        ...this.getOneData,
        ...this.getTwoData,
        ...this.getThreeData,
        ...this.getFourData,
        ...this.getFiveData,
        ...this.getSixData
      }
      kycEntitySubmit(query).then(res => {
        this.$message.success('上传成功')
      }).then(() => {
        this.nextBtnAllow = false
        this.$emit('scrollTotop')
        fetchEntity().then((res) => {
          const { data } = res
          this.dataLoding(data)
        })
      }).catch(() => {
        this.nextBtnAllow = false
        this.$emit('scrollTotop')
        this.$message.error('Server error!')
      })
      let nextTabIndex
      if (this.getCurrentTabIndex) {
        nextTabIndex = +this.getCurrentTabIndex + 1
      } else {
        nextTabIndex = 2
      }
      this.$router.push('/user/entityapplication/' + nextTabIndex)
    },
    handleToPrevBtn () {
      const nextTabIndex = +this.getCurrentTabIndex - 1
      if (nextTabIndex > 1) {
        this.$router.push('/user/entityapplication/' + nextTabIndex)
      } else {
        this.$router.push('/user/entityapplication/')
      }
    },
    handleTabClick (e) {
      if (e === this.getCurrentTabIndex) {
        return false
      }
      if (e !== '1') {
        this.$router.push('/user/entityapplication/' + e)
      } else {
        this.$router.push('/user/entityapplication/')
      }
    },
    handleSubmit () {
      if (this.disabled) {
        this.$router.push({ name: 'UserPage' })
        return
      }
      this.submitBtnAllow = true
      const query = {
        ...this.getOneData,
        ...this.getTwoData,
        ...this.getThreeData,
        ...this.getFourData,
        ...this.getFiveData,
        ...this.getSixData,
        is_submit: true
      }
      kycEntitySubmit(query).then(res => {
        this.$message.success('上传成功')
      }).then(() => {
        this.submitBtnAllow = false
        this.$router.push({ name: 'UserPage' })
        this.$store.dispatch('login/setUserDetailProfileAction')
      }).catch(() => {
        this.submitBtnAllow = false
        this.$message.error('Server error!')
      })
    }
  }

}
</script>

<style scoped>
.tabbar_slot{
  display: flex;
  align-items: center;
}
.tabbar_circle{
  width: 28px;
  margin-right: 10px;
  height: 28px;
  border-radius: 50%;
  font-weight: 500;
  border: 2px solid #ea7840;
  display: flex;
  align-items: center;
  justify-content: center;
}
.personApply_container_top_bar_tilte{
  font-size: 24px;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container_top_bar_subtilte{
  font-size: 35px;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container{
  min-height: 100vh;
  background: rgb(248,248 , 248);
  padding-bottom: 30px;
}
.button_wrapper{
  max-width: 700px;
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;
}
.personApply_container_top_bar{
  background: #373737;
  height: 170px;
  padding-top: 30px;
}
.btn_lay{
  width: 240px;
  height: 60px;
  border: 1px solid #ea7840;
  background: #fff;
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 300;
  color: #ea7840;
  transition: .2s;
  outline: none;
}
.btn_lay:hover{
  background: #ea7840;
  color: #fff;

}
.pre_btn:disabled{
  cursor: not-allowed;
}
.next_btn{
   background: #ea7840;
  color: #fff;
  position: relative;
}
.next_btn:disabled{
  background: rgb(168,168 , 168);
  border-color: rgb(168,168 , 168);
  color: rgb(0,0,0,.65);
  cursor: not-allowed;
}
.next_btn:disabled::after {
  position: absolute;
  top: 0;
  display: flex;
  content: 'Uploading..';
  font-weight: 400;
  color: rgb(0,0,0,.65);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(168,168 , 168);
}
@media only screen and (max-width: 768px) {
  .btn_lay{
  width: 36vw;
  height: 8vh;
  border: 1px solid #ea7840;
  background: #fff;
  font-size: 4.6vw;
  letter-spacing: 1px;
  font-weight: 300;
  color: #ea7840;
  transition: .2s;
  outline: none;
}
}
</style>
